<template>
  <div>
    <TheNavBar v-if="!showNav" />
    <SpinnersGlobal />
    <slot></slot>
    <!-- Start of HubSpot Embed Code -->
  </div>
</template>

<script setup>
const store = useGlobalStore();
const showNav = computed(() => store.getNavStatus);

onBeforeMount(() => {
  console.log("default.vue mounting");
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.id = "hs-script-loader";
  script.src = "https://js.hs-scripts.com/20294495.js";
  window.document.body.appendChild(script);

  window.beamer_config = { product_id: "blffVaxz12558", button_position: 'bottom-right' };
  const beamerScript = document.createElement("script");
  beamerScript.type = "text/javascript";
  beamerScript.src = "https://app.getbeamer.com/js/beamer-embed.js";
  beamerScript.defer = true
  window.document.body.appendChild(beamerScript);

  function onConversationsAPIReady() {
    console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);
    window.HubSpotConversations.widget.load();
  }
  /*
    configure window.hsConversationsSettings if needed.
  */
  window.hsConversationsSettings = {
    loadImmediately: false,
  };
  /*
    If external API methods are already available, use them.
  */
  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
  window.hsConversationsSettings = {
    loadImmediately: false,
    identificationEmail: store.$state.authInfo.account.Email,
    identificationToken: store.$state.authInfo.hubspotToken,
  };
});
</script>

<style>

svg[data-lastpass-icon="true"]{
    display:none !important;
 }
 
div[data-lastpass-icon-root]{
  display: none;
 }

html {
  @apply bg-gray-100;
}

/* Page-transition styles must be global */
/* page is used by default */
.page-leave-active,
.page-enter-active {
  transition: opacity 0.3s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}
/* slide-right */
.slide-right-leave-active,
.slide-right-enter-active {
  transition: all 0.3s;
}
.slide-right-enter {
  opacity: 0;
  transform: translate(-30px, 0);
}
.slide-right-enter-to,
.slide-right-leave {
  opacity: 1;
  transform: translate(0, 0);
}
.slide-right-leave-to {
  opacity: 0;
  transform: translate(30px, 0);
}
/* slide-left */
.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.3s;
}
.slide-left-enter {
  opacity: 0;
  transform: translate(30px, 0);
}
.slide-left-enter-to,
.slide-left-leave {
  opacity: 1;
  transform: translate(0, 0);
}
.slide-left-leave-to {
  opacity: 0;
  transform: translate(-30px, 0);
}
/* swipe-right */
.swipe-right-leave-active,
.swipe-right-enter-active {
  transition: all 0.3s;
}
.swipe-right-enter {
  opacity: 0;
  transform: translate(-100%, 0);
}
.swipe-right-enter-to,
.swipe-right-leave {
  opacity: 1;
  transform: translate(0, 0);
}
.swipe-right-leave-to {
  opacity: 0;
  transform: translate(100%, 0);
}
/* jump */
.jump-leave-active,
.jump-enter-active {
  transition: all 0.3s;
}
.jump-enter {
  opacity: 0;
  transform: rotate(-12deg);
}
.jump-enter-to,
.jump-leave {
  opacity: 1;
  transform: rotate(0deg);
}
.jump-leave-to {
  opacity: 0;
  transform: rotate(12deg);
}

::-moz-selection {
  @apply bg-blue-600 text-white;
}
::selection {
  @apply bg-blue-600 text-white;
}
</style>
